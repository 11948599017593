import React from "react"
import { StoryGroup } from "components"

const LinkMap = {
  RSC: "6357924b1125",
  "React Server Component": "6357924b1125",
  "React Boundaries": "e2d2b69eea16",
  CRA: "76edd054e9c",
  Suspense: "478a4d547ea3",
  "Partial Hydration": "478a4d547ea3",
  Streaming: "478a4d547ea3",
}

const storiesBasics = [
  {
    title: "CRA Neden Sonlandırıldı",
    postId: "76edd054e9c",
  },
  {
    title: "React 18 Suspense, Streaming, Partial Hydration",
    postId: "478a4d547ea3",
  },
  {
    title: "RSC (React Server Component) Nedir ?",
    postId: "6357924b1125",
  },
  {
    title: "React Boundaries",
    postId: "e2d2b69eea16",
  },
  {
    title: "RSC'nin Ekosistemindeki Kütüphanelere Etkisi",
    postId: "227d48583069",
  },
  {
    title: "React Component Modeli",
    postId: "6b5f8da94f7",
  },
]

const storiesGroup = [
  {
    title: "RSC",
    storyPart: storiesBasics,
  },
]

export const context = {
  stories: storiesGroup,
  linkMap: LinkMap,
  title: "React Server Components",
  path: "react-server-components",
}

const ReactRouterPage = () => {
  return (
    <StoryGroup
      title={context.title}
      complex={true}
      stories={context.stories}
      linkMap={context.linkMap}
    />
  )
}

export default ReactRouterPage
